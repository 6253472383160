.modal {
  // display: none !important;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.modalinner {
  padding: 20px;
  width: 30%;
}

.close {
  color: rgb(255, 255, 255);
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: rgb(211, 211, 211);
  text-decoration: none;
  cursor: pointer;
}