.selector {
  width: 400px;
  height: 566px;
  background-position: center top;
  padding-top: 25px;

  &.left {

    border-radius: 0 0 20px 20px;

  }

  &.right {

    border-radius: 0 0 20px 20px;
  }

  .nfts {
    padding: 10px 0px 0 30px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    height: 480px;
    margin-right: 10px;

    &.centered {
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    &.loading img {
      opacity: 0.7;
    }


  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 0px 10px;
    border-radius: 20px;


    button {
      background: #447843;
      border: 1px solid rgba(255, 255, 255, 0.12);
      color: #E6E6CA;
      font-weight: 600;
      font-size: 15px;
      font-weight: 800;
      padding: 13px 15px;
      margin-right: 5px;
      border-radius: 100px;
      cursor: pointer;
      line-height: 18px;
      border: 2px solid rgba(0, 0, 0, 0.1);


      &[disabled] {
        cursor: default;
        opacity: 0.3;
      }

      &.claim {
        background: linear-gradient(106.12deg, #A88848 -2.23%, #FCEEB2 34.99%, #DDB55B 81.52%, #FEEFB1 160.19%);
        box-shadow: 0px 0px 3px #000000;
        border-radius: 5px;
        color: #7C5D1E;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
      }
    }

    &.right {
      justify-content: flex-start;
      padding-left: 15px;
    }
  }


}

.noNfts {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  h3 {
    color: white;
  }

  a {
    display: block;
    color: #fff;
    font-weight: bold;
    display: block;
    padding: 0 20px;
    line-height: 50px;
    background: linear-gradient(139.3deg, #AFD55B -19.63%, #5F8900 76.88%) !important;
    text-decoration: none;
    border-radius: 5px;
  }
}

.spinner {
  position: absolute !important;
  top: 50%;
  left: 50%;
  margin-top: -64px;
  margin-left: -32px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 10px;
  border-radius: 20px;

  button {
    background: linear-gradient(106.12deg, #A88848 -2.23%, #FCEEB2 34.99%, #DDB55B 81.52%, #FEEFB1 160.19%);
    box-shadow: 0px 0px 3px #000000;
    border-radius: 5px;
    color: #7C5D1E;
    font-weight: 600;
    font-size: 15px;
    font-weight: 800;
    padding: 13px 15px;
    margin-right: 5px;
    border-radius: 10px;
    cursor: pointer;
    line-height: 18px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    margin-top: 8px !important;

    &[disabled] {
      cursor: default;
      opacity: 0.3;
    }

    &.claim {
      background: linear-gradient(106.12deg, #A88848 -2.23%, #FCEEB2 34.99%, #DDB55B 81.52%, #FEEFB1 160.19%);
      box-shadow: 0px 0px 3px #000000;
      border-radius: 5px;
      color: #7C5D1E;
      box-shadow: 0 0 5px #1f1d1b;
    }
  }

  &.right {
    justify-content: flex-start;
    padding-left: 15px;
  }
}

.nftwrap {
  width: 100%;
  border-radius: 0 0 20px 20px;
  display: flex;
  flex-direction: column;
  position: relative;

  .nftpanel {
    backdrop-filter: blur(2px);
    border-radius: 15px;
    margin: 2px;
    display: flex;
    flex-wrap: wrap;
    height: 500px;
    overflow-y: auto;
    align-content: flex-start;
    justify-content: flex-start;
    padding: 5px 10px 5px 10px;
    border: 1px solid #5F8900;

    .image-wrapper {
      width: 110px;
      height: 110px;
      margin: 7px;
      position: relative;
      display: flex;
      border-radius: 10px;
      cursor: pointer;

      img {
        width: 100%;
        border-radius: 10px;
      }

      .selected {
        position: absolute;
        width: 110px;
        height: 110px;
        border-radius: 10px;
        box-shadow: 0 0px 10px #e0c489;
        cursor: pointer;
        z-index: 1;
      }
    }
  }

  .nftpanelr {
    padding: 15px 10px 15px 18px;
    align-content: flex-start;
  }
}

.separator {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #FEEFB1 50%, rgba(0, 0, 0, 0) 100%);
  width: 94%;
  height: 1px;
  position: absolute;
  top: 44.5%;
}

.gen1 {

  border: 0px;
  display: flex;
  overflow-x: auto;
  padding-bottom: 3px;
  min-height: 235px;
  width: 100%;


  .image-wrapper {
    width: 210px;
    height: 210px;
    margin: 4px;
    margin: 4px 8px 4px 0;
    position: relative;
    display: flex;


    img {
      width: 210px;
      height: 210px;
      border-radius: 20px;
      display: block;
      position: relative;
      cursor: pointer;
    }
  }


}

.gen2 {

  border: 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 7px;
  overflow-x: auto;
  width: 100%;
  height: 243px;
  margin: 5px 0px;
  align-content: flex-start;

  .image-wrapper {
    width: 105px;
    height: 105px;
    margin: 6px 6px 3px 0;
    position: relative;

    img {
      width: 105px;
      height: 105px;
      border-radius: 10px;
      display: block;
      position: relative;
      cursor: pointer;
      margin-bottom: 5px;
    }

    .selected {
      width: 105px;
      height: 105px;
      border-radius: 10px;
      box-shadow: 0 0px 10px #e0c489;
      border: 4px solid #e0c489;
      cursor: pointer;
      position: absolute;
      z-index: 1;
    }
  }
}





@media (max-width: 600px) {
  .selector {
    border-radius: 30px;
    border: 5px solid #ffe1ca;
    width: 100%;

    &.left {
      background: rgba(0, 0, 0, 0.7);
      border-radius: 20px;
    }

    &.right {
      background: rgba(0, 0, 0, 0.7);
      border-radius: 20px;
    }

    .image-wrapper {}
  }

  .nfts {
    padding-left: 5px !important;
    margin-right: 0px !important;
    height: 380px !important;
    margin-bottom: 20px !important;
  }

  .buttons {
    flex-direction: column !important;
    margin-right: 0 !important;
    margin-left: 0 !important;

    &.right {}

    button {
      width: 100%;
      margin-bottom: 5px !important;
    }
  }

  .separator {
    position: absolute;
    top: 40%;
  }
}