.App {
  text-align: center;
}

.Balance {
  height: 40px;
  line-height: 40px
}

iframe {
  display: none !important;
}